import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'

import Container from '@components/modules/global/container'
import Blockquote from '@components/text/blockquote'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  quoteRoot: {
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(16),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(20),
    },
  },
  quoteContainer: {
    position: 'relative',
  },
  quoteImage: {
    position: 'relative',
    marginTop: theme.spacing(10),
    marginRight: theme.spacing(-4),
    marginBottom: theme.spacing(-16),
    marginLeft: theme.spacing(-4),
    textAlign: 'center',
    zIndex: 1,

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: theme.spacing(-10),
      left: theme.spacing(10),
      margin: 0,
      width: '247px',
    },
  },
  quoteText: {
    position: 'relative',
    zIndex: 2,
  },

  quoteIdent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(46),
      paddingTop: theme.spacing(10),
    },
  },
}))

export type QuoteProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  quote?: string
  author?: string
  position?: string
  image?: DBN.Contentful.IAsset
}

export default function CaseIntro({
  theme,
  anchor,
  quote,
  author,
  position,
  image,
}: QuoteProps): ReactElement {
  const classes = useStyles()
  const gatsbyImage = image ? getImage(image) : null

  return (
    <Module className={classes.quoteRoot} theme={theme} anchor={anchor}>
      <Container type="nomargin" className={clsx(classes.quoteContainer)}>
        {quote && (
          <div
            className={clsx(classes.quoteText, {
              [classes.quoteIdent]: !!image,
            })}
          >
            <Blockquote text={quote} author={author} position={position} />
          </div>
        )}
        {image && gatsbyImage && (
          <div className={clsx(classes.quoteImage)}>
            <GatsbyImage
              image={gatsbyImage}
              alt={image.description || ''}
              title={image.title}
              imgStyle={{
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
            />
          </div>
        )}
      </Container>
    </Module>
  )
}
