import React, { ReactElement, useState, useEffect, useRef } from 'react'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import useCustomCursor from '@system/hooks/useCustomCursor'

import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import Container from '@components/modules/global/container'
import Copy from '@components/core/copy'
import Module from '@components/core/module'
import SocialIcon from '@components/core/socialicon'
import Carousel from '@components/core/carousel'

const useStyles = makeStyles((theme) => ({
  familyHeadsRoot: {
    position: 'relative',
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(18),
    },
  },
  familyHeadsSlider: {
    overflow: 'visible !important',
    [theme.breakpoints.up('md')]: {
      pointerEvents: 'none',
    },
  },
  familyHeadsSliderWrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  familyHeadsSlide: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    },
  },
  familyHeadsSliderPrev: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '50%',
  },
  familyHeadsSliderNext: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '50%',
  },
  familyHeadsHeadline: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-4),
    },
  },
  familyHeadsCopy: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  familyMemberRoot: {},
  familyMemberMedia: {
    position: 'relative',
    color: theme.palette.text.invert,
    backgroundColor: theme.palette.background.focus,
    marginBottom: theme.spacing(5),
    overflow: 'hidden',
  },

  familyMemberImage: {
    transform: 'scale(1)',
    transition: 'transform 0.6s ease-out',
  },

  familyMemberIcons: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      pointerEvents: 'auto',
    },
  },
  familyMemberSocialIcon: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
  },
  familyMemberDescription: {
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out',
    [theme.breakpoints.up('md')]: {
      pointerEvents: 'auto',
    },
  },
  familyMemberName: {
    marginBottom: theme.spacing(2),
  },
  familyMemberPosition: {
    marginBottom: theme.spacing(1),
  },

  familyMemberAnimatePrev: {
    '& $familyMemberImage': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
    '& $familyMemberDescription': {
      transform: 'translate(-16px, 0)',
      transition: 'transform 0.6s ease-in',
    },
  },
  familyMemberAnimateNext: {
    '& $familyMemberImage': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
    '& $familyMemberDescription': {
      transform: 'translate(16px, 0)',
      transition: 'transform 0.6s ease-in',
    },
  },
}))

export type FamilyMemberProps = {
  name?: string
  position?: string
  linkedin?: string
  image?: DBN.Contentful.IAsset
  animating?: string | null
}

export type FamilyHeadsProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  headline?: string
  copy?: DBN.Contentful.BasicRichTextType
  members?: Array<FamilyMemberProps>
}

function FamilyMember({
  name,
  position,
  linkedin,
  image,
  animating,
}: FamilyMemberProps): ReactElement {
  const classes = useStyles()
  const gatsbyImage = image ? getImage(image) : null

  return (
    <div
      className={clsx(classes.familyMemberRoot, {
        [classes.familyMemberAnimatePrev]: animating == 'prev',
        [classes.familyMemberAnimateNext]: animating == 'next',
      })}
    >
      <div className={classes.familyMemberMedia}>
        {image && gatsbyImage && (
          <GatsbyImage
            image={gatsbyImage}
            alt={image.description || ''}
            title={image.title}
            className={classes.familyMemberImage}
          />
        )}
        <div className={classes.familyMemberIcons}>
          {linkedin && (
            <SocialIcon
              item={{
                name: 'LinkedIn',
                href: linkedin,
              }}
              className={classes.familyMemberSocialIcon}
            />
          )}
        </div>
      </div>
      <div className={classes.familyMemberDescription}>
        {name && (
          <Headline level={3} className={classes.familyMemberName}>
            {name}
          </Headline>
        )}
        {position && (
          <Paragraph className={classes.familyMemberPosition}>
            {position}
          </Paragraph>
        )}
      </div>
    </div>
  )
}

function FamilyHeadsMobile({
  headline,
  copy,
  members,
}: FamilyHeadsProps): ReactElement {
  const classes = useStyles()
  const [animating, setAnimating] = useState(null)

  return (
    <>
      <Container type="nomargin">
        <Grid container spacing={8}>
          <Grid item xs={12}>
            {headline && (
              <Headline level={21} className={classes.familyHeadsHeadline}>
                {parse(headline)}
              </Headline>
            )}
            {copy && (
              <Copy richtext={copy} className={classes.familyHeadsCopy} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Carousel
              speed={800}
              className={classes.familyHeadsSlider}
              onSlideNextTransitionStart={() => setAnimating('next')}
              onSlidePrevTransitionStart={() => setAnimating('prev')}
              onSlideChangeTransitionEnd={() => setAnimating(null)}
            >
              {members?.map((member: FamilyMemberProps, index: index) => (
                <div className={classes.familyHeadsSlide} key={index}>
                  <FamilyMember
                    name={member.name}
                    position={member.position}
                    linkedin={member.linkedin}
                    image={member.image}
                    animating={animating}
                  />
                </div>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

function FamilyHeadsDesktop({
  headline,
  copy,
  members,
}: FamilyHeadsProps): ReactElement {
  const classes = useStyles()
  const [swiper, setSwiper] = useState(null)
  const { setCursorType } = useCustomCursor()
  const containerRef = useRef<HTMLDivElement>(null)
  const [animating, setAnimating] = useState(null)
  const [touchStart, setTouchStart] = React.useState(0)
  const [touchEnd, setTouchEnd] = React.useState(0)

  const handleTouchStart = (e: TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX)
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    const diff = 50
    if (touchStart - touchEnd > diff) {
      if (swiper && !swiper.isEnd) swiper.slideNext()
    }

    if (touchStart - touchEnd < -diff) {
      if (swiper && !swiper.isBeginning) swiper.slidePrev()
    }
    setTouchStart(0)
    setTouchEnd(0)
  }

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        containerRef.current.style.marginRight = -''
        containerRef.current.style.paddingRight = ''

        const containerRect = containerRef.current.getBoundingClientRect()

        const test =
          window.innerWidth - containerRect.left - containerRect.width

        containerRef.current.style.marginRight = -1 * test + 'px'
        containerRef.current.style.paddingRight = test + 'px'
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Container type="nomargin">
        <Grid container spacing={8}>
          <Grid item xs={4}>
            {(headline || copy) && (
              <div className={classes.familyHeadsSlide}>
                {headline && (
                  <Headline level={21} className={classes.familyHeadsHeadline}>
                    {parse(headline)}
                  </Headline>
                )}
                {copy && (
                  <Copy richtext={copy} className={classes.familyHeadsCopy} />
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={8}>
            <div
              className={classes.familyHeadsSliderWrapper}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              ref={containerRef}
            >
              <div
                className={classes.familyHeadsSliderPrev}
                onMouseEnter={() => {
                  if (swiper !== null && !swiper.isBeginning) {
                    setCursorType('arrow-left')
                  }
                }}
                onMouseLeave={() => setCursorType('')}
                onClick={() => {
                  if (swiper !== null && !swiper.isBeginning) {
                    swiper.slidePrev()
                    if (swiper.isBeginning) {
                      setCursorType('')
                    }
                  }
                }}
              ></div>
              <div
                className={classes.familyHeadsSliderNext}
                onMouseEnter={() => {
                  if (swiper !== null && !swiper.isEnd) {
                    setCursorType('arrow-right')
                  }
                }}
                onMouseLeave={() => setCursorType('')}
                onClick={() => {
                  if (swiper !== null && !swiper.isEnd) {
                    swiper.slideNext()
                    if (swiper.isEnd) {
                      setCursorType('')
                    }
                  }
                }}
              ></div>
              <Carousel
                className={classes.familyHeadsSlider}
                speed={800}
                spaceBetween={32}
                slidesPerView={2}
                onSwiper={setSwiper}
                onSlideNextTransitionStart={() => setAnimating('next')}
                onSlidePrevTransitionStart={() => setAnimating('prev')}
                onSlideChangeTransitionEnd={() => setAnimating(null)}
              >
                {members?.map((member: FamilyMemberProps, index: index) => (
                  <div className={classes.familyHeadsSlide} key={index}>
                    <FamilyMember
                      name={member.name}
                      position={member.position}
                      linkedin={member.linkedin}
                      image={member.image}
                      animating={animating}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default function FamilyHeads({
  theme,
  anchor,
  headline,
  copy,
  members,
}: FamilyHeadsProps): ReactElement {
  const classes = useStyles()
  const [mobile, setMobile] = useState(true)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')

    function mediaHandler(this: MediaQueryList) {
      if (this.matches) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }

    if (mediaQuery.matches) {
      setMobile(false)
    }

    mediaQuery.addListener(mediaHandler)
    return () => {
      mediaQuery.removeListener(mediaHandler)
    }
  })

  return (
    <Module theme={theme} anchor={anchor} className={classes.familyHeadsRoot}>
      {mobile ? (
        <FamilyHeadsMobile headline={headline} copy={copy} members={members} />
      ) : (
        <FamilyHeadsDesktop headline={headline} copy={copy} members={members} />
      )}
    </Module>
  )
}
