import { HistoryLocation } from 'reach__router'
import React, { ReactElement } from 'react'

import PageModuleParser from './PageModuleParser'

export type PageModuleProps = DBN.IReactDefaultProps & {
  pagemodule: DBN.Contentful.PageModuleUnion
  location?: HistoryLocation
  pageContext?: DBN.PageHelpers.PageContext
}

export default function PageModule({
  pagemodule,
  location,
  pageContext,
}: PageModuleProps): ReactElement {
  const Parser = PageModuleParser.find((parsermodule) => {
    return pagemodule.__typename === parsermodule.__typename
  })

  if (!Parser) {
    console.warn('Module not defined: ', pagemodule)
    return <></>
  }

  return React.createElement(
    Parser.component,
    Parser.props(pagemodule, location, pageContext)
  )
}
