import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  vSpacerRoot: {},
  desktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  mobile: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}))

export type VerticalSpacerProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: string
  sizeDesktop?: number
  sizeMobile?: number
}

export default function VerticalSpacer({
  theme,
  sizeDesktop,
  sizeMobile,
}: VerticalSpacerProps): ReactElement {
  const classes = useStyles()
  return (
    <Module theme={theme} className={classes.vSpacerRoot}>
      {sizeDesktop && (
        <div
          className={classes.desktop}
          style={{ height: sizeDesktop + 'px' }}
        />
      )}
      {sizeMobile && (
        <div className={classes.mobile} style={{ height: sizeMobile + 'px' }} />
      )}
    </Module>
  )
}
