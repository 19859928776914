import React, { ReactElement, useState, useEffect } from 'react'
import clsx from 'clsx'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { InView } from 'react-intersection-observer'

import useCustomCursor from '@system/hooks/useCustomCursor'

import Headline from '@components/text/headline'
import Container from '@components/modules/global/container'
import Carousel from '@components/core/carousel'
import PageLink from '@components/core/pagelink'
import Module from '@components/core/module'

import Copy from '@components/core/copy'

const useStyles = makeStyles((theme) => ({
  teaserRoot: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(30),
    },
  },
  teaserHalf: {
    paddingTop: 0,
  },
  teaserHeadline: {
    marginBottom: 0,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-4),
    },
  },
  teaserRow: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(8),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(30),
      },
    },
  },
  teaserRowLeft: {
    justifyContent: 'flex-start',
  },
  teaserRowRight: {
    justifyContent: 'flex-end',
  },
  teaserSlider: {
    overflow: 'visible !important',
  },
  teaserSlide: {
    paddingRight: theme.spacing(4),
  },
  teaserItem: {
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in',
    display: 'block',
    marginBottom: theme.spacing(3),
  },
  teaserItemImage: {
    display: 'block',
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
    '&:hover $teaserItemImageZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
  },
  teaserItemImageZoom: {
    pointerEvents: 'none',
    transition: 'transform 0.6s ease-out',
  },
  teaserItemDescription: {
    opacity: 0,
    transition: 'opacity 0.6s',
  },
  teaserItemTitle: {
    marginBottom: theme.spacing(2),
  },
  teaserItemCopy: {
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  teaserItemFadeIn: {
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out',

    '& $teaserItemDescription': {
      opacity: 1,
      transition: 'opacity 0.6s',
    },
  },
}))

export type TeaserPageProps = {
  teaserTitle?: string
  teaserCopy?: DBN.Contentful.BasicRichTextType
  teaserImageLandscape?: DBN.Contentful.IAsset
  teaserImageSquare?: DBN.Contentful.IAsset
  fields: {
    fullPath: string
    isExternal: boolean
  }
}

export type TeaserRowProps = {
  align?: string
  pages?: Array<TeaserPageProps>
}

export type TeaserProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  type?: string
  headline?: string
  rows?: Array<TeaserRowProps>
}

export type TeaserItemProps = {
  page: TeaserPageProps
  type: string
}

export type TeaserContentProps = {
  headline?: string
  rows?: Array<TeaserRowProps>
}

function TeaserImage({ page, type }: TeaserItemProps): ReactElement {
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()

  const cursor = page.fields
    ? page.fields?.isExternal
      ? 'teaserExternal'
      : 'teaserMore'
    : ''

  switch (type) {
    case 'half':
      const imageLandscape = page.teaserImageLandscape
        ? getImage(page.teaserImageLandscape)
        : null
      return (
        <>
          {page.teaserImageLandscape && imageLandscape && (
            <div
              className={classes.teaserItemImage}
              onMouseEnter={() => setCursorType(cursor)}
              onMouseLeave={() => setCursorType('')}
              onClick={() => setCursorType('')}
            >
              <GatsbyImage
                image={imageLandscape}
                alt={page.teaserImageLandscape.description || ''}
                title={page.teaserImageLandscape.title}
                className={classes.teaserItemImageZoom}
              />
            </div>
          )}
        </>
      )
    case 'third':
      const imageSquare = page.teaserImageSquare
        ? getImage(page.teaserImageSquare)
        : null
      return (
        <>
          {page.teaserImageSquare && imageSquare && (
            <div
              className={classes.teaserItemImage}
              onMouseEnter={() => setCursorType(cursor)}
              onMouseLeave={() => setCursorType('')}
              onClick={() => setCursorType('')}
            >
              <GatsbyImage
                image={imageSquare}
                alt={page.teaserImageSquare.description || ''}
                title={page.teaserImageSquare.title}
                className={classes.teaserItemImageZoom}
              />
            </div>
          )}
        </>
      )
  }
  return <></>
}

function TeaserItem({ page, type }: TeaserItemProps): ReactElement {
  const classes = useStyles()

  return (
    <InView threshold={0} triggerOnce={true} delay={100}>
      {({ inView, ref }) => (
        <div ref={ref}>
          <PageLink
            page={page}
            className={clsx(classes.teaserItem, {
              [classes.teaserItemFadeIn]: inView,
            })}
          >
            <TeaserImage page={page} type={type} />
            <div className={classes.teaserItemDescription}>
              {page.teaserTitle && (
                <Headline className={classes.teaserItemTitle} level={3}>
                  {page.teaserTitle}
                </Headline>
              )}
              {page.teaserCopy && (
                <Copy
                  className={classes.teaserItemCopy}
                  richtext={page.teaserCopy}
                />
              )}
            </div>
          </PageLink>
        </div>
      )}
    </InView>
  )
}

function TeaserHalf({ headline, rows }: TeaserContentProps): ReactElement {
  const classes = useStyles()

  return (
    <>
      {rows?.map((row: TeaserRowProps, i: number) =>
        row.pages && row.pages.length ? (
          <Grid container key={i} spacing={8}>
            {headline && i == 0 && (
              <Grid item xs={12} md={6}>
                {headline && (
                  <Headline level={21} className={classes.teaserHeadline}>
                    {headline}
                  </Headline>
                )}
              </Grid>
            )}

            {row.pages?.map((page: TeaserPageProps, j: number) => (
              <Grid item xs={12} md={6} key={j}>
                <TeaserItem page={page} type="half" />
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )
      )}
    </>
  )
}

function TeaserThirdSlider({
  headline,
  rows,
}: TeaserContentProps): ReactElement {
  const classes = useStyles()

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        {headline && (
          <Headline level={21} className={classes.teaserHeadline}>
            {headline}
          </Headline>
        )}
      </Grid>
      <Grid item xs={12}>
        <Carousel className={classes.teaserSlider}>
          {rows?.map((row: TeaserRowProps, i: number) =>
            row.pages?.map((page: TeaserPageProps, j: number) => (
              <div
                className={classes.teaserSlide}
                key={'row-' + i + '_item-' + j}
              >
                <TeaserItem page={page} type="third" />
              </div>
            ))
          )}
        </Carousel>
      </Grid>
    </Grid>
  )
}

function TeaserThird({ headline, rows }: TeaserContentProps): ReactElement {
  const classes = useStyles()

  return (
    <>
      {rows?.map((row: TeaserRowProps, i: number) =>
        row.pages && row.pages.length ? (
          <Grid
            container
            key={i}
            spacing={8}
            className={clsx(classes.teaserRow, {
              [classes.teaserRowLeft]: row?.align === 'left',
              [classes.teaserRowRight]: row?.align === 'right',
            })}
          >
            {headline && i == 0 && (
              <Grid item xs={12} md={4}>
                <Headline level={21} className={classes.teaserHeadline}>
                  {headline}
                </Headline>
              </Grid>
            )}
            {row.pages?.map((page: TeaserPageProps, j: number) => (
              <Grid item xs={12} md={4} key={j}>
                <TeaserItem page={page} type="third" />
              </Grid>
            ))}
          </Grid>
        ) : (
          <></>
        )
      )}
    </>
  )
}

export default function Teaser({
  theme,
  anchor,
  type,
  headline,
  rows,
}: TeaserProps): ReactElement {
  const classes = useStyles()

  const [mobile, setMobile] = useState(true)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')

    function mediaHandler(this: MediaQueryList) {
      if (this.matches) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }

    if (mediaQuery.matches) {
      setMobile(false)
    }

    mediaQuery.addListener(mediaHandler)
    return () => {
      mediaQuery.removeListener(mediaHandler)
    }
  })

  function TeaserVariant() {
    switch (type) {
      case 'half':
        return <TeaserHalf rows={rows} headline={headline} />
      case 'third':
        return mobile ? (
          <TeaserThirdSlider rows={rows} headline={headline} />
        ) : (
          <TeaserThird rows={rows} headline={headline} />
        )
    }
    return <></>
  }

  return (
    <Module
      theme={theme}
      anchor={anchor}
      className={clsx(classes.teaserRoot, {
        [classes.teaserHalf]: type === 'half',
      })}
    >
      <Container type="nomargin">{rows && <TeaserVariant />}</Container>
    </Module>
  )
}
