import React, { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles(() => ({
  soundControlRoot: {
    position: 'relative',
    width: '60px',
    height: '100%',
    textAlign: 'center',
  },
  toggleButton: {
    background: '#99000000',
    lineHeight: '52px',
    border: 'none',
    cursor: 'none',
    '&.fullscreen': {
      cursor: 'auto',
    },
    '& > span': {
      width: '16px',
      height: '16px',
      background: '#ff9900',
      display: 'inline-block',
    },
  },
  slider: {
    position: 'absolute',
    height: '60px',
    left: '12px',
    bottom: '40px',
    color: '#ff0000', // theme.palette.common.turquoise,
    zIndex: 1,
    '& .MuiSlider-root': {
      color: '#29ace3',
      cursor: 'none',
    },
    '&.fullscreen .MuiSlider-root': {
      cursor: 'auto',
    },
  },
}))

type SoundControlProps = DBN.IReactDefaultProps & {
  volume?: number
  muted?: boolean
  fullscreen?: boolean
  onChange?: (event: React.ChangeEvent<any>, value: number | number[]) => void
  onToggleMute: (event: React.MouseEvent<HTMLDivElement>) => void
}

export default function SoundControl({
  volume,
  muted,
  fullscreen,
  onChange,
  onToggleMute,
}: SoundControlProps): ReactElement {
  const classes = useStyles()
  const [isSliderVisible, setSliderVisibility] = useState(false)

  const showSlider = (bool: boolean) => {
    if (bool === true) {
      setSliderVisibility(true)
    } else {
      setSliderVisibility(false)
    }
  }

  function VolumeIcon(props: { volume: number; muted: boolean }) {
    const volume = props.volume
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g fill="#ffffff">
          {volume > 0.5 && !muted && (
            <path d="M9,-1.13686838e-13 L9,16 L4,12 L-5.68434189e-14,12 L-5.68434189e-14,4 L4,4 L9,-1.13686838e-13 Z M11.0010895,0.0295874628 C13.959633,1.46171136 16,4.49257905 16,8 C16,11.5074209 13.959633,14.5382886 11.0010895,15.9704125 L11.0016,14.0249757 C12.985279,12.7542287 14.3,10.5306491 14.3,8 C14.3,5.46935089 12.985279,3.24577132 11.0016,1.97502435 L11.0010895,0.0295874628 Z M7.299,3.537 L4.5963278,5.7 L1.699,5.7 L1.699,10.3 L4.5963278,10.3 L7.299,12.462 L7.299,3.537 Z M10.9994532,4.49952075 C11.9106478,5.29791934 12.5,6.56871307 12.5,8 C12.5,9.43128693 11.9106478,10.7020807 10.9994532,11.5004792 L10.9994532,4.49952075 Z" />
          )}
          {volume <= 0.5 && !muted && (
            <path d="M9,-1.13686838e-13 L9,16 L4,12 L-5.68434189e-14,12 L-5.68434189e-14,4 L4,4 L9,-1.13686838e-13 Z M7.299,3.537 L4.5963278,5.7 L1.699,5.7 L1.699,10.3 L4.5963278,10.3 L7.299,12.462 L7.299,3.537 Z M10.9994532,4.49952075 C11.9106478,5.29791934 12.5,6.56871307 12.5,8 C12.5,9.43128693 11.9106478,10.7020807 10.9994532,11.5004792 L10.9994532,4.49952075 Z" />
          )}
          {muted && muted === true && (
            <path d="M1.81421356,6.21724894e-15 L15.9563492,14.1421356 L14.5421356,15.5563492 L13.3251518,14.339565 C12.6468764,15.000358 11.8629418,15.553221 11.0010895,15.9704125 L11.0016,14.0249757 C11.4048877,13.7666291 11.7805261,13.4688999 12.1230887,13.1372145 L9,10.014 L9,16 L4,12 L-4.97379915e-14,12 L-4.97379915e-14,4 L2.986,4 L0.4,1.41421356 L1.81421356,6.21724894e-15 Z M4.645,5.659 L4.5963278,5.7 L1.699,5.7 L1.699,10.3 L4.5963278,10.3 L7.299,12.462 L7.299,8.313 L4.645,5.659 Z M11.0010895,0.0295874628 C13.959633,1.46171136 16,4.49257905 16,8 C16,9.1393387 15.7847029,10.2283921 15.3925733,11.2286955 L14.0488552,9.88519651 C14.2125953,9.28459176 14.3,8.65250311 14.3,8 C14.3,5.46935089 12.985279,3.24577132 11.0016,1.97502435 L11.0010895,0.0295874628 Z M10.9994532,4.49952075 C11.9106478,5.29791934 12.5,6.56871307 12.5,8 C12.5,8.10959998 12.4965442,8.21825888 12.4897529,8.32583666 L10.999,6.836 L10.9994532,4.49952075 Z M9,-1.13686838e-13 L9,4.837 L6.312,2.15 L9,-1.13686838e-13 Z" />
          )}
        </g>
      </svg>
    )
  }

  return (
    <div
      className={classes.soundControlRoot}
      onMouseEnter={() => showSlider(true)}
      onMouseLeave={() => showSlider(false)}
    >
      {isSliderVisible && (
        <div className={clsx(classes.slider, { fullscreen: fullscreen })}>
          <Slider
            orientation="vertical"
            aria-labelledby="vertical-slider"
            value={muted === true ? 0 : volume}
            min={0}
            step={0.1}
            max={1}
            onChange={onChange}
          />
        </div>
      )}
      <button
        className={clsx(classes.toggleButton, { fullscreen: fullscreen })}
        onClick={() => onToggleMute()}
      >
        <VolumeIcon volume={volume || 0} muted={muted || false} />
      </button>
    </div>
  )
}
