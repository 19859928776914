import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  togglePlayRoot: {
    width: '60px',
    height: '100%',
    background: '#99000000',
    textAlign: 'center',
    lineHeight: '52px',
    border: 'none',
    cursor: 'none',
    '&.fullscreen': {
      cursor: 'auto',
    },
    '& > span': {
      width: '16px',
      height: '16px',
      background: '#ff9900',
      display: 'inline-block',
    },
  },
}))

type ToggelPlayProps = DBN.IReactDefaultProps & {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isPlaying?: boolean
  fullscreen?: boolean
}

export default function TogglePlay({
  onClick,
  isPlaying,
  fullscreen,
}: ToggelPlayProps): ReactElement {
  const classes = useStyles()
  return (
    <button
      className={clsx(classes.togglePlayRoot, { fullscreen: fullscreen })}
      onClick={(e) => onClick(e)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g>
          {isPlaying && (
            <path
              fill="#FFFFFF"
              d="M6.5,0 L6.5,16 L0,16 L0,0 L6.5,0 Z M16,0 L16,16 L9.5,16 L9.5,0 L16,0 Z M4.7,1.8 L1.8,1.8 L1.8,14.2 L4.7,14.2 L4.7,1.8 Z M14.2,1.8 L11.3,1.8 L11.3,14.2 L14.2,14.2 L14.2,1.8 Z"
            ></path>
          )}
          {!isPlaying && (
            <path
              fill="#FFFFFF"
              d="M2.73219382,0 L14.469007,8 L2.73219382,16 L2.73219382,0 Z M4.732,3.783 L4.732,12.216 L10.918,8 L4.732,3.783 Z"
            ></path>
          )}
        </g>
      </svg>
    </button>
  )
}
