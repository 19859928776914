import React, { ReactElement } from 'react'
import DefaultVideoVariant from './variants/default'
import Module from '@components/core/module'

export type VideoProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  anchor?: string
  youtubeId?: string
  poster?: Array<DBN.Contentful.IAsset>
  posterMobile?: Array<DBN.Contentful.IAsset>
  posterPortrait?: Array<DBN.Contentful.IAsset>
  portraitFormat?: boolean
  blackBorder?: boolean
}

export default function Video({ ...props }: VideoProps): ReactElement {
  return (
    <Module theme="light" anchor={props.anchor}>
      <DefaultVideoVariant {...props} />
    </Module>
  )
}
