import React, { ReactElement, useState, useEffect } from 'react'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import useCustomCursor from '@system/hooks/useCustomCursor'
import { InView } from 'react-intersection-observer'

import Headline from '@components/text/headline'
import Copy from '@components/core/copy'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'
import PageLink from '@components/core/pagelink'
import Carousel from '@components/core/carousel'

const useStyles = makeStyles((theme) => ({
  teaserTextRoot: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(30),
    },
  },
  teaserTextHeadline: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  teaserTextHeadlineSlider: {
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
  teaserTextGrid: {
    margin: theme.spacing(-4, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-6, 0),
    },
  },
  teaserTextGridItem: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 0),
    },
  },
  teaserTextSlider: {
    overflow: 'visible !important',
  },
  teaserTextSlide: {
    paddingRight: theme.spacing(4),
  },
  teaserTextItem: {
    display: 'block',
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in',
    '&:hover $teaserTextItemImage': {
      transform: 'scale(1.1)',
      transition: 'transform 0.6s ease-in',
    },
  },
  teaserTextItemImage: {
    pointerEvents: 'none',
    marginBottom: theme.spacing(4),
    transition: 'transform 0.6s ease-out',
  },
  teaserTextItemDescription: {
    opacity: 0,
    transition: 'opacity 0.6s',
  },
  teaserTextItemHeadline: {
    marginTop: 0,
    marginBottom: theme.spacing(4),
  },
  teaserTextItemCopy: {
    marginTop: 0,
    marginBottom: theme.spacing(3),
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  teaserTextItemFadeIn: {
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out',
    '& $teaserTextItemDescription': {
      opacity: 1,
    },
  },
}))

export type TeaserTextPageProps = {
  teaserTitle?: string
  teaserCopy?: DBN.Contentful.BasicRichTextType
  teaserImage?: DBN.Contentful.IAsset
  fields: {
    fullPath: string
    isExternal: boolean
  }
}

export type TeaserTextProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  slider?: boolean
  headline?: string
  pages?: Array<TeaserTextPageProps>
}

export type TeaserTextItemProps = DBN.IReactDefaultProps & {
  page: TeaserTextPageProps
}

export function TeaserTextItem({ page }: TeaserTextItemProps): ReactElement {
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()
  const gatsbyImage = page.teaserImage ? getImage(page.teaserImage) : null
  const cursor = page.fields
    ? page.fields?.isExternal
      ? 'teaserExternal'
      : 'teaserMore'
    : ''

  return (
    <InView threshold={0} triggerOnce={true} delay={100}>
      {({ inView, ref }) => (
        <div ref={ref}>
          <PageLink
            page={page}
            className={clsx(classes.teaserTextItem, {
              [classes.teaserTextItemFadeIn]: inView,
            })}
          >
            <div
              onMouseEnter={() => setCursorType(cursor)}
              onMouseLeave={() => setCursorType('')}
              onClick={() => setCursorType('')}
            >
              {page.teaserImage && gatsbyImage && (
                <GatsbyImage
                  image={gatsbyImage}
                  alt={page.teaserImage.description || ''}
                  title={page.teaserImage.title}
                  className={classes.teaserTextItemImage}
                  imgStyle={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    width: 'auto',
                    height: 'auto',
                    marginTop: 'auto',
                    marginRight: 'auto',
                  }}
                />
              )}
              <div className={classes.teaserTextItemDescription}>
                {page.teaserTitle && (
                  <Headline
                    level={3}
                    className={classes.teaserTextItemHeadline}
                  >
                    {page.teaserTitle}
                  </Headline>
                )}
                {page.teaserCopy && (
                  <Copy
                    richtext={page.teaserCopy}
                    className={classes.teaserTextItemCopy}
                  />
                )}
              </div>
            </div>
          </PageLink>
        </div>
      )}
    </InView>
  )
}

export function TeaserTextMobile({ pages }: TeaserTextProps): ReactElement {
  const classes = useStyles()

  return (
    <div className={classes.teaserTextGrid}>
      <Grid container spacing={8}>
        {pages?.map((page: TeaserTextPageProps, index: number) => (
          <Grid item key={index} xs={12}>
            <div className={classes.teaserTextGridItem}>
              <TeaserTextItem page={page} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export function TeaserTextMobileSlider({
  pages,
}: TeaserTextProps): ReactElement {
  const classes = useStyles()

  return (
    <Carousel className={classes.teaserTextSlider}>
      {pages?.map((page: TeaserTextPageProps, index: number) => (
        <div className={classes.teaserTextSlide} key={index}>
          <TeaserTextItem page={page} />
        </div>
      ))}
    </Carousel>
  )
}

export function TeaserTextDesktop({ pages }: TeaserTextProps): ReactElement {
  const classes = useStyles()

  return (
    <div className={classes.teaserTextGrid}>
      <Grid container spacing={8}>
        {pages?.map((page: TeaserTextPageProps, index: number) => (
          <Grid item key={index} md={4}>
            <div className={classes.teaserTextGridItem}>
              <TeaserTextItem page={page} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default function TeaserText({
  theme,
  anchor,
  slider,
  headline,
  pages,
}: TeaserTextProps): ReactElement {
  const classes = useStyles()

  const [mobile, setMobile] = useState(true)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')

    function mediaHandler(this: MediaQueryList) {
      if (this.matches) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }

    if (mediaQuery.matches) {
      setMobile(false)
    }

    mediaQuery.addListener(mediaHandler)
    return () => {
      mediaQuery.removeListener(mediaHandler)
    }
  })

  return pages && pages.length ? (
    <Module theme={theme} anchor={anchor} className={classes.teaserTextRoot}>
      <Container type="nomargin">
        {headline && (
          <Headline
            level={21}
            className={clsx(classes.teaserTextHeadline, {
              [classes.teaserTextHeadlineSlider]: slider,
            })}
          >
            {headline}
          </Headline>
        )}
        {mobile ? (
          slider ? (
            <TeaserTextMobileSlider pages={pages} />
          ) : (
            <TeaserTextMobile pages={pages} />
          )
        ) : (
          <TeaserTextDesktop pages={pages} />
        )}
      </Container>
    </Module>
  ) : (
    <></>
  )
}
