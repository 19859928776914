import React, { ReactElement, useMemo } from 'react'
import { Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

const useStyles = makeStyles((theme) => ({
  backgroundMediaRoot: {
    color: '#ffffff',
    height: '100vh',
    width: '100vw',
  },
  video: {
    height: '100vh',
    width: '100vw',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  image: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    margin: '0 !important',
    zIndex: 0,
    '& .gatsby-image-wrapper': {
      height: '100vh',
    },
    [theme.breakpoints.up('lg')]: {
      '& .gatsby-image-wrapper': {
        height: '100vh',
      },
    },
  },
}))

export type BackgroundMediaProps = {
  className?: string
  media?: DBN.Contentful.IAsset
  mediaMobile?: DBN.Contentful.IAsset
  fallbackImage?: DBN.Contentful.IAsset
}

export default function BackgroundMedia({
  media,
}: BackgroundMediaProps): ReactElement {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const desktop = media[0]
  const mobile = media[1]
  const fallback = media[2] || null
  const type = useMemo(
    () =>
      isLarge
        ? desktop?.file?.contentType.split('/')[0]
        : mobile?.file?.contentType.split('/')[0],
    [isLarge]
  )
  const poster = fallback ? getSrc(fallback) : ''

  const getBestImage = () => {
    if (mobile?.file && isMobile === true) {
      return getImage(mobile)
    } else if (desktop?.file) {
      return getImage(desktop)
    }
    return
  }

  return (
    <>
      {type === 'video' && desktop?.file && (
        <div className={classes.backgroundMediaRoot}>
          {mobile && mobile?.file && isMobile === true && (
            <video
              className={classes.video}
              poster={poster}
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
            >
              <source
                src={mobile?.file.url}
                type={mobile?.file.contentType}
              ></source>
            </video>
          )}
          {desktop && desktop?.file && isMobile === false && (
            <video
              className={classes.video}
              poster={poster}
              autoPlay
              muted
              loop
              playsInline
            >
              <source
                src={desktop?.file.url}
                type={desktop?.file.contentType}
              ></source>
            </video>
          )}
        </div>
      )}

      {type === 'image' && getBestImage() && (
        <div className={classes.backgroundMediaRoot}>
          <GatsbyImage
            className={classes.image}
            image={getBestImage()}
            alt={desktop.description}
            title={desktop.title}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'top center',
            }}
            layout="fullWidth"
          />
        </div>
      )}
    </>
  )
}
