import RtTypes from '@contentful/rich-text-types'

import Headline, { HeadlineProps } from '@components/text/headline'
import Blockquote, { QuoteProps } from '@components/text/blockquote'

function findParser(
  component: DBN.Contentful.ArticleModuleUnion
): ComponentParserType | undefined {
  const parser = ComponentParser.find((cmp) => {
    return component.__typename.match(cmp.nodeType)
  })

  return parser
}

export type ComponentParserPropsUnion = HeadlineProps | QuoteProps
type ComponentParserType = {
  nodeType: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => React.ReactElement
  props: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any
  ) => ComponentParserPropsUnion
}

const ComponentParser: Array<ComponentParserType> = [
  {
    nodeType: 'heading',
    component: Headline,
    props: (component: DBN.Contentful.Heading): HeadlineProps => {
      return {
        level: component.level,
        id: component.content
          ? (component.content[0] as RtTypes.Text)?.value
              .replace(/\s+/g, '')
              .toLowerCase()
          : '',
        children: component.content?.map((ct) => (ct as RtTypes.Text).value),
      }
    },
  },
  {
    nodeType: 'blockquote',
    component: Blockquote,
    props: (component: RtTypes.Quote): QuoteProps => {
      return {
        text: (component.content?.[0].content[0] as RtTypes.Text)?.value,
        author:
          component.content.length > 1
            ? (component.content?.[1].content[0] as RtTypes.Text)?.value
            : '',
      }
    },
  },
]

export { findParser }

export default ComponentParser
