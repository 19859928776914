import React, {
  ReactElement,
  TouchEvent,
  useRef,
  useEffect,
  useState,
} from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import useCustomCursor from '@system/hooks/useCustomCursor'
import { InView } from 'react-intersection-observer'

const useStyles = makeStyles((theme) => ({
  sliderGridContainer: {
    position: 'relative',
    width: '100%',
  },
  prevArea: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '100%',
  },
  nextArea: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
  },
  sliderGridList: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'nowrap',
    pointerEvents: 'none',
    transform: 'translateZ(0)',
    transition: 'left 0.8s ease-in-out',
    listStyle: 'none',
    padding: '0',
    marginTop: theme.spacing(24),
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(18),
    },
  },
  sliderGridItem: {
    padding: 0,
    margin: 0,
  },

  sliderGridItemActive: {
    pointerEvents: 'auto',
  },
  headline: {
    opacity: 0,
    transition: 'opacity 0.6s ease-in',
    position: 'absolute',
    zIndex: 0,
    margin: '0 auto',
    top: '-96px',
    width: '100%',
    ...theme.typography.sliderbg,

    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      top: 0,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: '20vw',
      paddingRight: '20vw',
    },
  },
  headlineInView: {
    opacity: 1,
  },
}))

export type SliderProps = DBN.IReactDefaultProps & {
  headline?: string
  onSlideNextTransitionStart?: () => void
  onSlidePrevTransitionStart?: () => void
  onSlideChangeTransitionEnd?: () => void
}

export default function Slider({
  headline,
  children,
  onSlideNextTransitionStart,
  onSlidePrevTransitionStart,
  onSlideChangeTransitionEnd,
}: SliderProps): ReactElement {
  const classes = useStyles()
  const cnt = React.Children.count(children) || 0

  const [sliderPosX, setSliderPosX] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)

  const sliderRef = useRef<HTMLUListElement>(null)

  const bP = 768

  useEffect(() => {
    function handleResize() {
      setWindowWidth(() => {
        return document.documentElement.clientWidth || document.body.clientWidth
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const selectPrev = () => {
    if (sliderPosX > 0) {
      if (onSlidePrevTransitionStart) onSlidePrevTransitionStart.call()
      setSliderPosX(sliderPosX - 1)
      if (onSlideChangeTransitionEnd)
        setTimeout(() => onSlideChangeTransitionEnd.call(), 800)
    }

    if (sliderPosX <= 1) {
      setCursorType('')
    }
  }

  const selectNext = () => {
    if (sliderPosX < cnt - 1) {
      if (onSlideNextTransitionStart) onSlideNextTransitionStart.call()
      setSliderPosX(sliderPosX + 1)
      if (onSlideChangeTransitionEnd)
        setTimeout(() => onSlideChangeTransitionEnd.call(), 800)
    }
    if (sliderPosX >= cnt - 2) {
      setCursorType('')
    }
  }

  const getElementWidth = () => {
    if (windowWidth < bP) {
      return Math.min(520, windowWidth - 48)
    }
    return Math.min(520, (windowWidth - 112) / 2)
  }

  const getSpace = () => {
    if (windowWidth < bP) {
      return windowWidth - getSpaceStart() - getElementWidth() - 16
    }

    return windowWidth - getSpaceStart() - 1.75 * getElementWidth()
  }

  const getSpaceStart = () => {
    if (windowWidth < bP) {
      return 16
    }
    return Math.max(40, (windowWidth - 1072) * 0.5)
  }

  const getSliderWidth = () => {
    return cnt * getElementWidth() + getSpace() * (cnt - 1)
  }

  const getPosX = () => {
    return -sliderPosX * (getElementWidth() + getSpace())
  }

  const { setCursorType } = useCustomCursor()

  const [touchStart, setTouchStart] = React.useState(0)
  const [touchEnd, setTouchEnd] = React.useState(0)

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    setTouchStart(e.targetTouches[0].clientX)
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    const diff = 50
    if (touchStart - touchEnd > diff) {
      selectNext()
    }

    if (touchStart - touchEnd < -diff) {
      selectPrev()
    }
    setTouchStart(0)
    setTouchEnd(0)
  }

  return (
    <>
      {cnt && cnt > 0 && (
        <div
          className={classes.sliderGridContainer}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {headline && (
            <InView threshold={0} triggerOnce={true} delay={100}>
              {({ inView, ref }) => (
                <div
                  ref={ref}
                  className={clsx(classes.headline, {
                    [classes.headlineInView]: inView,
                  })}
                >
                  {headline}
                </div>
              )}
            </InView>
          )}
          <div
            className={classes.prevArea}
            onClick={selectPrev}
            onMouseEnter={() => {
              setCursorType(sliderPosX > 0 ? 'arrow-left' : '')
            }}
            onMouseLeave={() => setCursorType('')}
          />
          <div
            className={classes.nextArea}
            onClick={selectNext}
            onMouseEnter={() => {
              setCursorType(sliderPosX < cnt - 1 ? 'arrow-right' : '')
            }}
            onMouseLeave={() => setCursorType('')}
          />
          <ul
            className={classes.sliderGridList}
            ref={sliderRef}
            style={{
              left: `${getPosX()}px`,
              width: `${getSliderWidth()}px`,
              marginLeft: `${getSpaceStart()}px`,
            }}
          >
            {(() => {
              return React.Children.map(children, (child, index) => {
                return React.cloneElement(
                  <li
                    className={clsx(classes.sliderGridItem, {
                      [classes.sliderGridItemActive]: index == sliderPosX,
                    })}
                    key={`gallery-item-${index}`}
                    style={{ width: `${getElementWidth()}px` }}
                  >
                    {child}
                  </li>
                )
              })
            })()}
          </ul>
        </div>
      )}
    </>
  )
}
