import React, { ReactElement, useRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Grid } from '@material-ui/core'
import { InView } from 'react-intersection-observer'

import Headline from '@components/text/headline'
import Container from '@components/modules/global/container'
import Copy from '@components/core/copy'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  textImageRoot: {
    paddingTop: theme.spacing(20),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(17),
    },
    flexGrow: 1,
  },
  image: {
    display: 'block !important',
    width: '100%',
    margin: 0,
    '& > div': {
      maxWidth: 'none !important',
    },
    [theme.breakpoints.up('sm')]: {
      '& > div': {
        maxWidth: '520px !important',
        paddingRight: theme.spacing(4),
        boxSizing: 'content-box',
      },
    },
  },
  contentWrapper: {
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in, opacity 0.6s ',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
    },
  },
  contentWrapperFadeIn: {
    opacity: 1,
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out, opacity 0.6s ',
  },
  contentInner: {
    '& :last-child': {
      marginBottom: 0,
    },
  },
  headline: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0),
    },
  },
  copy: {
    marginTop: theme.spacing(4),
  },
  link: {
    marginTop: theme.spacing(6),
  },
}))

export type TextImageProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: string
  anchor?: string
  type?: string
  headline?: string
  copy?: DBN.Contentful.BasicRichTextType
  link?: DBN.Contentful.BasicRichTextType
  image?: DBN.Contentful.IAsset
}

export default function TextImage({
  theme,
  anchor,
  type,
  headline,
  copy,
  link,
  image,
}: TextImageProps): ReactElement {
  const classes = useStyles()

  const elementRef = useRef<HTMLDivElement>(null)
  const [scale, setScale] = useState(1)

  useEffect(() => {
    const windowH =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight

    function handleScroll() {
      const elementPositionY =
        windowH - (elementRef.current?.getBoundingClientRect().top || 0)
      const scrollArea = 2 * windowH
      if (elementPositionY > 0 && elementPositionY < scrollArea) {
        const sf = 1 + (elementPositionY / scrollArea) * 0.4
        setScale(sf)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Module theme={theme} anchor={anchor}>
      <div
        className={clsx(classes.textImageRoot, {
          [classes.textImageNews]: type == 'news',
        })}
        ref={elementRef}
      >
        <Container type="nomargin">
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            spacing={8}
          >
            <Grid item xs={12} sm={6}>
              {image && getImage(image) && (
                <GatsbyImage
                  image={getImage(image)}
                  alt={image.description}
                  title={image.title}
                  imgStyle={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                    transform: `scale(${scale})`,
                  }}
                  className={classes.image}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <InView threshold={0} triggerOnce={true} delay={100}>
                {({ inView, ref }) => (
                  <div
                    className={clsx(classes.contentWrapper, {
                      [classes.contentWrapperFadeIn]: inView,
                    })}
                    ref={ref}
                  >
                    <div className={classes.contentInner}>
                      {headline && (
                        <Headline className={classes.headline} level={2}>
                          {headline}
                        </Headline>
                      )}
                      {copy && (
                        <Copy className={classes.copy} richtext={copy} />
                      )}
                      {type == 'news' && link && (
                        <Copy
                          className={classes.link}
                          richtext={link}
                          type="teaser"
                        />
                      )}
                    </div>
                  </div>
                )}
              </InView>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Module>
  )
}
