import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { InView } from 'react-intersection-observer'
import { Grid } from '@material-ui/core'
import Container from '@components/modules/global/container'
import Copy from '@components/core/copy'
import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  textRoot: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(30),
    },
  },
  contentWrapper: {
    marginBottom: 0,
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in, opacity 0.6s',
  },
  contentWrapperFadeIn: {
    transform: 'translate(0, 0)',
    opacity: 1,
    transition: 'transform 0.6s ease-out, opacity 0.6s',
  },
  tagline: {
    marginBottom: 0,
    paddingBottom: theme.spacing(4),
    ...theme.typography.introtext2,
    color: theme.palette.text.hint,
  },
  headline: {
    marginBottom: 0,
  },
  headline2: {
    marginBottom: 0,
    paddingBottom: theme.spacing(4),
  },
  subline: {
    marginBottom: 0,
    ...theme.typography.subtitle1,
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
    },
  },
  copyContainer: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: theme.spacing(4),

    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  copy: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    '& :last-child': {
      marginBottom: 0,
    },
  },
  copyColumns: {
    [theme.breakpoints.up('md')]: {
      columns: '2',
      columnGap: theme.spacing(8),
    },
  },
}))

export type TextProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: string
  layout?: string
  anchor?: string
  tagline?: string
  headline?: string
  headline2?: string
  subline?: string
  copy?: DBN.Contentful.BasicRichTextType
}

export default function Text({
  theme,
  anchor,
  layout = 'full',
  tagline,
  headline,
  headline2,
  subline,
  copy,
}: TextProps): ReactElement {
  const classes = useStyles()

  return (
    <>
      <InView threshold={0} triggerOnce={true} delay={100}>
        {({ inView, ref }) => (
          <Module theme={theme} anchor={anchor} className={classes.textRoot}>
            <div ref={ref}>
              <Container
                type="nomargin"
                className={clsx(classes.contentWrapper, {
                  [classes.contentWrapperFadeIn]: inView,
                })}
              >
                {tagline && (
                  <Paragraph className={classes.tagline}>{tagline}</Paragraph>
                )}
                {headline && (
                  <Headline level={1} className={classes.headline}>
                    {headline}
                  </Headline>
                )}
                {headline2 && (
                  <Headline level={21} className={classes.headline2}>
                    {headline2}
                  </Headline>
                )}
                {subline && (
                  <Paragraph className={classes.subline}>{subline}</Paragraph>
                )}

                {copy && (
                  <Grid container spacing={8} className={classes.copyContainer}>
                    <Grid item xs={12} md={layout == 'half' ? 6 : 12}>
                      <Copy
                        className={clsx(classes.copy, {
                          [classes.copyColumns]: layout == 'columns',
                        })}
                        richtext={copy}
                      />
                    </Grid>
                  </Grid>
                )}
              </Container>
            </div>
          </Module>
        )}
      </InView>
    </>
  )
}
