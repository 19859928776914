import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  toggleFullscreenRoot: {
    width: '60px',
    height: '100%',
    background: '#99000000',
    textAlign: 'center',
    lineHeight: '52px',
    border: 'none',
    cursor: 'none',
    '&.fullscreen': {
      cursor: 'auto',
    },
    '& > span': {
      width: '16px',
      height: '16px',
      background: '#ff9900',
      display: 'inline-block',
    },
  },
}))

type ToggleFullScreenProps = DBN.IReactDefaultProps & {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  isFullscreen?: boolean
}

export default function ToggleFullScreen({
  onClick,
  isFullscreen,
}: ToggleFullScreenProps): ReactElement {
  const classes = useStyles()
  return (
    <button
      className={clsx(classes.toggleFullscreenRoot, {
        fullscreen: isFullscreen,
      })}
      onClick={(e) => onClick(e)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g fill="#FFFFFF">
          {isFullscreen && (
            <g>
              <path
                d="M6.5,2.85993451e-13 L6.5,2 L2,2 L2,6.5 L9.32587341e-15,6.5 L9.32587341e-15,2.85993451e-13 L6.5,2.85993451e-13 Z"
                transform="translate(3.250000, 3.250000) rotate(-180.000000) translate(-3.250000, -3.250000) "
              ></path>
              <path
                d="M16,9.5 L16,11.5 L11.5,11.5 L11.5,16 L9.5,16 L9.5,9.5 L16,9.5 Z"
                transform="translate(12.750000, 12.750000) rotate(-360.000000) translate(-12.750000, -12.750000) "
              ></path>
              <path
                d="M6.5,9.5 L6.5,11.5 L2,11.5 L2,16 L-2.13162821e-14,16 L-2.13162821e-14,9.5 L6.5,9.5 Z"
                transform="translate(3.250000, 12.750000) rotate(-270.000000) translate(-3.250000, -12.750000) "
              ></path>
              <path
                d="M16,-3.50830476e-14 L16,2 L11.5,2 L11.5,6.5 L9.5,6.5 L9.5,-3.50830476e-14 L16,-3.50830476e-14 Z"
                transform="translate(12.750000, 3.250000) rotate(-90.000000) translate(-12.750000, -3.250000) "
              ></path>
            </g>
          )}
          {!isFullscreen && (
            <g>
              <path d="M16,9.5 L16,16 L9.5,16 L9.5,14 L14,14 L14,9.5 L16,9.5 Z M2.13694209e-16,9.5 L2,9.5 L2,14 L6.5,14 L6.5,16 L-1.77635684e-15,16 L2.13694209e-16,9.5 Z M6.5,0 L6.5,2 L2,2 L2,6.5 L-8.8817842e-16,6.5 L-8.8817842e-16,0 L6.5,0 Z M16,5.74404342e-14 L16,6.5 L14,6.5 L14,2 L9.5,2 L9.5,5.62464035e-14 L16,5.74404342e-14 Z"></path>
            </g>
          )}
        </g>
      </svg>
    </button>
  )
}
