import React, { ReactElement } from 'react'
import { HistoryLocation } from '@reach/router'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import ContentfulComponentArray from '@system/utils/ContentfulComponentArray'

const useStyles = makeStyles(() => ({
  copyRoot: {},
}))

export type CopyProps = {
  location?: HistoryLocation
  pageContext?: DBN.PageHelpers.PageContext
  className?: string
  id?: string
  type?: 'default' | 'simple' | 'button' | 'teaser' | 'container'
  theme?: string
  richtext: DBN.Contentful.BasicRichTextType
}

export default function Copy({
  location,
  pageContext,
  className,
  richtext,
  type = 'default',
  theme,
  id,
}: CopyProps): ReactElement {
  const classes = useStyles()

  if (type === 'simple') {
    return (
      <ContentfulComponentArray
        componentarray={richtext}
        simple
        location={location}
        pageContext={pageContext}
      />
    )
  }

  if (type === 'button') {
    return (
      <div id={id} className={clsx(className, classes.copyRoot)}>
        <ContentfulComponentArray
          componentarray={richtext}
          button
          theme={theme}
          location={location}
          pageContext={pageContext}
        />
      </div>
    )
  }

  if (type === 'teaser') {
    return (
      <div id={id} className={clsx(className, classes.copyRoot)}>
        <ContentfulComponentArray
          componentarray={richtext}
          teaser
          location={location}
          pageContext={pageContext}
        />
      </div>
    )
  }

  if (type === 'container') {
    return (
      <div id={id} className={clsx(className, classes.copyRoot)}>
        <ContentfulComponentArray
          componentarray={richtext}
          container
          theme={theme}
          location={location}
          pageContext={pageContext}
        />
      </div>
    )
  }

  return (
    <div id={id} className={clsx(className, classes.copyRoot)}>
      <ContentfulComponentArray
        componentarray={richtext}
        location={location}
        pageContext={pageContext}
      />
    </div>
  )
}
