import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { InView } from 'react-intersection-observer'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import useGlobalText from '@system/hooks/useGlobalText'

import Headline from '@components/text/headline'
import Container from '@components/modules/global/container'
import Paragraph from '@components/text/paragraph'
import Module from '@components/core/module'

import Copy from '@components/core/copy'

const useStyles = makeStyles((theme) => ({
  caseIntroRoot: {
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(20),
  },
  caseIntroHeadline: {
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-out, opacity 0.6s',
  },
  caseIntroSubline: {
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-out 0.2s, opacity 0.6s 0.1s',
    ...theme.typography.introsubline,
    marginBottom: theme.spacing(6),
  },
  caseIntroCopy: {
    opacity: 0,
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-out 0.4s, opacity 0.6s 0.2s',
  },
  caseIntroMeta: {
    opacity: 0,
    transform: 'translate(10vh, 0)',
    transition: 'transform 0.6s ease-out 0.3s, opacity 0.6s 0.3s',
  },
  caseIntroFallbackImage: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    maxWidth: '244px',
    maxHeight: '50px',
    objectFit: 'contain',
    objectPosition: 'left top',
  },
  caseIntroMetaEntry: {
    marginTop: theme.spacing(8),
  },
  caseIntroMetaHead: {
    ...theme.typography.intrometahead,
  },
  caseIntroMetaBody: {
    ...theme.typography.intrometabody,
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  caseIntroMetaAward: {
    '&:not(:first-child)': {
      marginTop: theme.spacing(1),
    },
  },

  caseIntroFadeIn: {
    '& $caseIntroHeadline': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
    '& $caseIntroSubline': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
    '& $caseIntroCopy': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
    '& $caseIntroMeta': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
  },
}))

export type CaseIntroAward = {
  name?: string
}

export type CaseIntroProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  headline?: string
  subline?: string
  copy?: DBN.Contentful.BasicRichTextType
  logo?: DBN.Contentful.IAsset
  agency?: string
  year?: string
  awards?: Array<CaseIntroAward>
}

export default function CaseIntro({
  theme,
  anchor,
  headline,
  subline,
  copy,
  logo,
  agency,
  year,
  awards,
}: CaseIntroProps): ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const image = logo ? getImage(logo) : null

  return (
    <Module theme={theme} anchor={anchor} className={classes.caseIntroRoot}>
      <InView threshold={0} triggerOnce={true} delay={100}>
        {({ inView, ref }) => (
          <div ref={ref}>
            <Container
              type="nomargin"
              className={clsx({
                [classes.caseIntroFadeIn]: inView,
              })}
            >
              <Grid container justifyContent="space-between" spacing={8}>
                {headline && (
                  <Grid
                    item
                    xs={12}
                    className={clsx(classes.caseIntroHeadline)}
                  >
                    <Headline level={1}>{headline}</Headline>
                  </Grid>
                )}
                <Grid item md={7} lg={6}>
                  {subline && (
                    <Paragraph className={classes.caseIntroSubline}>
                      {subline}
                    </Paragraph>
                  )}
                  {copy && (
                    <Copy richtext={copy} className={classes.caseIntroCopy} />
                  )}
                </Grid>
                <Grid item md={4} lg={5}>
                  <div className={classes.caseIntroMeta}>
                    {logo ? (
                      image ? (
                        <GatsbyImage
                          image={image}
                          alt={logo.description || ''}
                          title={logo.title}
                          objectFit="contain"
                          objectPosition="top left"
                        />
                      ) : (
                        <img
                          src={logo.file?.url}
                          alt={logo.description || ''}
                          title={logo.title}
                          className={classes.caseIntroFallbackImage}
                        />
                      )
                    ) : (
                      <></>
                    )}

                    {agency && (
                      <div className={classes.caseIntroMetaEntry}>
                        <div className={classes.caseIntroMetaHead}>
                          {getText('module.caseIntro.agency')}
                        </div>
                        <div className={classes.caseIntroMetaBody}>
                          {agency}
                        </div>
                      </div>
                    )}
                    {year && (
                      <div className={classes.caseIntroMetaEntry}>
                        <div className={classes.caseIntroMetaHead}>
                          {getText('module.caseIntro.year')}
                        </div>
                        <div className={classes.caseIntroMetaBody}>{year}</div>
                      </div>
                    )}
                    {awards && (
                      <div className={classes.caseIntroMetaEntry}>
                        <div className={classes.caseIntroMetaHead}>
                          {getText('module.caseIntro.awards')}
                        </div>
                        <ul className={classes.caseIntroMetaBody}>
                          {awards?.map((award: CaseIntroAward, i: number) => (
                            <li className={classes.caseIntroMetaAward} key={i}>
                              {award.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        )}
      </InView>
    </Module>
  )
}
