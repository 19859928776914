import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'
import { default as HeadlineObject } from '@components/text/headline'

const useStyles = makeStyles((theme) => ({
  headlineRoot: {
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(48),
      paddingBottom: theme.spacing(12),
    },
  },
  headlineHeadline: {
    margin: 0,
  },
}))

export type HeadlineProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  headline?: string
}

export default function Headline({
  theme,
  anchor,
  headline,
}: HeadlineProps): ReactElement {
  const classes = useStyles()

  return headline ? (
    <Module theme={theme} anchor={anchor} className={classes.headlineRoot}>
      <Container type="nomargin">
        <HeadlineObject level={1} className={classes.headlineHeadline}>
          {headline}
        </HeadlineObject>
      </Container>
    </Module>
  ) : (
    <></>
  )
}
