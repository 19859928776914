import React, { ReactElement, ReactNode } from 'react'
import { Link } from 'gatsby'
import { CasePage } from '@/types//casepage'
import { ExternalPage } from '@/types/externalpage'
import { GenericPage } from '@/types/genericpage'
import { InternalPage } from '@/types/internalpage'
import { PressPage } from '@/types/presspage'
import { TeaserPageProps } from '@/components/teaser'

export type PageLinkProps = {
  children?: ReactNode
  className?: string
  page:
    | CasePage
    | ExternalPage
    | GenericPage
    | InternalPage
    | PressPage
    | TeaserPageProps
}

export default function PageLink({
  children,
  className,
  page,
  ...props
}: PageLinkProps): ReactElement {
  return page.fields ? (
    page.fields.isExternal ? (
      <a
        className={className}
        href={page.fields?.fullPath}
        target="_blank"
        rel="noreferrer"
        {...props}
      >
        {children}
      </a>
    ) : (
      <Link className={className} to={page.fields?.fullPath} {...props}>
        {children}
      </Link>
    )
  ) : (
    <span className={className} {...props}>
      {children}
    </span>
  )
}
