import React, { ReactElement } from 'react'
import DefaultStageVariant, { DefaultStageProps } from './variants/default'

type StagePropsUnion = DefaultStageProps

export type StageProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  type?: string
} & StagePropsUnion

export default function Stage({ type, ...props }: StageProps): ReactElement {
  const extendedProps = { type: type?.toLocaleLowerCase(), ...props }
  const widthParallax = { parallax: true, ...extendedProps }
  switch (type?.toLowerCase()) {
    case 'work':
      return <DefaultStageVariant {...widthParallax} />
    default:
      return <DefaultStageVariant {...extendedProps} />
  }
}
