import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InView } from 'react-intersection-observer'
import Container from '@components/modules/global/container'
import Copy from '@components/core/copy'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  introRoot: {
    marginBottom: 0,
  },

  contentWrapper: {
    marginTop: theme.spacing(12),
    marginBottom: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(30),
    },
    '& > :nth-child(1)': {
      marginBottom: 0,
      opacity: 0,
      transform: 'translate(0, 10vh)',
      transition: 'opacity 0.6s, transform 0.6s ease-out',
    },
    '&.fade-in > :nth-child(1)': {
      transform: 'translate(0, 0)',
      opacity: 1,
    },
  },
  copyContainer: {},
  copy: {
    marginBottom: 0,
    ...theme.typography.h2,
    '& p': {
      marginBottom: 0,
    },
  },
}))

export type IntroProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: string
  anchor?: string
  copy?: DBN.Contentful.BasicRichTextType
}

export default function Intro({
  theme,
  copy,
  anchor,
}: IntroProps): ReactElement {
  const classes = useStyles()

  return (
    <>
      <InView threshold={0.4} triggerOnce={true}>
        {({ inView, ref }) => (
          <Module theme={theme} anchor={anchor} className={classes.introRoot}>
            <div ref={ref}>
              <Container
                className={
                  classes.contentWrapper +
                  ' ' +
                  (inView === true ? 'fade-in' : 'fade-out')
                }
              >
                {copy && <Copy className={classes.copy} richtext={copy} />}
              </Container>
            </div>
          </Module>
        )}
      </InView>
    </>
  )
}
