import React, { ReactElement } from 'react'
import parse from 'html-react-parser'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@components/modules/global/container'
import { Typography } from '@material-ui/core'
import useCustomCursor from '@system/hooks/useCustomCursor'
import PageLink from '@components/core/pagelink'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  caseNextRoot: {},
  contentWrapper: {
    textAlign: 'center',
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(16),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(30),
      marginBottom: theme.spacing(30),
    },
  },
  copyContainer: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  pageLink: {},
  tagLine: {
    marginBottom: theme.spacing(2),
    ...theme.typography.subtitle1,
  },
  title: {
    marginBottom: 0,
    ...theme.typography.h2,
  },
}))

export type NextCaseProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: string
  anchor?: string
  teaserTagline?: string
  casePage?: DBN.Contentful.ITeaserPage
}

export default function NextCase({
  theme,
  anchor,
  teaserTagline,
  casePage,
}: NextCaseProps): ReactElement {
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()
  const cursor = casePage?.fields
    ? casePage.fields?.isExternal
      ? 'teaserExternal'
      : 'teaserMore'
    : ''

  return (
    <Module theme={theme} anchor={anchor} className={classes.caseNextRoot}>
      <Container className={classes.contentWrapper}>
        {casePage && (
          <PageLink
            page={casePage}
            className={classes.pageLink}
            onMouseEnter={() => setCursorType(cursor)}
            onMouseLeave={() => setCursorType('')}
            onClick={() => setCursorType('')}
          >
            {teaserTagline && (
              <Typography
                variant="h3"
                component="p"
                className={classes.tagLine}
              >
                {parse(
                  teaserTagline
                    .replaceAll('  ', '<br>')
                    .replaceAll('®', '<sup>®</sup>')
                    .replaceAll('©', '<sup>©</sup>')
                    .replaceAll('℗', '<sup>℗</sup>')
                )}
              </Typography>
            )}

            {casePage?.teaserTitle && (
              <Typography variant="h2" component="h3" className={classes.title}>
                {parse(
                  casePage.teaserTitle
                    .replaceAll('  ', '<br>')
                    .replaceAll('®', '<sup>®</sup>')
                    .replaceAll('©', '<sup>©</sup>')
                    .replaceAll('℗', '<sup>℗</sup>')
                )}
              </Typography>
            )}
          </PageLink>
        )}
      </Container>
    </Module>
  )
}
