import React, { ReactElement, useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'gatsby'
import useCustomCursor from '@system/hooks/useCustomCursor'
import Container from '@components/modules/global/container'
import Headline from '@components/text/headline'
import Module from '@components/core/module'
import Paragraph from '@components/text/paragraph'
import Paginator from '@components/core/pagination'
import useDateFns from '@system/hooks/useDateFns'

const useStyles = makeStyles((theme) => ({
  pressListRoot: {
    paddingBottom: theme.spacing(30),
  },
  pressListHeadline: {
    margin: theme.spacing(12, 0, 8, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(30, 0, 11, 0),
    },
  },
  pressListList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  pressListEntry: {
    marginTop: theme.spacing(12),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20),
    },
  },
  pressListEntryTitle: {
    margin: 0,
  },
  pressListEntryMeta: {
    margin: 0,
  },
  pressListPagination: {
    marginTop: theme.spacing(11),
  },
}))

export interface PressPageRelated {
  id?: string
  teaserTitle?: string
  releaseDate?: string
  fields: {
    fullPath: string
    isExternal: boolean
  }
}

export type PressListProps = DBN.IReactDefaultProps & {
  pageContext?: DBN.PageHelpers.PageContext
  theme?: string
  anchor?: string
  count?: number
  headline?: string
  related?: Array<PressPageRelated>
}

export default function PressList({
  pageContext,
  theme,
  anchor,
  count = 0,
  headline,
  related,
}: PressListProps): ReactElement {
  const classes = useStyles()
  const [entries, setEntries] = useState([])
  const [pagedEntries, setPagedEntries] = useState([])
  const [page, setPage] = useState(1)
  const perPage = 8
  const { setCursorType } = useCustomCursor()
  const { formatDate } = useDateFns()

  const { allPressPages } = useStaticQuery(graphql`
    query {
      allPressPages: allContentfulPressPage(
        sort: { fields: [releaseDate], order: DESC }
        filter: { url: { ne: null }, teaserTitle: { ne: null } }
      ) {
        nodes {
          id
          releaseDate
          teaserTitle
          fields {
            fullPath
            isExternal
          }
          node_locale
        }
      }
    }
  `)

  let pages = allPressPages.nodes.filter(
    (page) => page.node_locale == pageContext?.locale
  )

  if (related) {
    const ids = related.map((page) => page.id)

    pages = related.concat(
      pages.filter((element) => {
        return !ids.includes(element.id)
      })
    )
  }

  if (count > 0) {
    pages = pages.slice(0, count)
  }

  const paginationHandler = (event: React.ChangeEvent, page: number) => {
    setPagedEntries(entries.slice((page - 1) * perPage, page * perPage))
    setPage(page)
  }

  useEffect(() => {
    setEntries(pages)
    setPagedEntries(pages.slice(0, perPage))
    setPage(1)
  }, [])

  return (
    <Module theme={theme} anchor={anchor} className={classes.pressListRoot}>
      <Container type="nomargin">
        {headline && (
          <Headline level={3} className={classes.pressListHeadline}>
            {headline}
          </Headline>
        )}
        <ul className={classes.pressListList}>
          {pagedEntries.map((pressPage: PressPageRelated, index: number) => (
            <li className={classes.pressListEntry} key={index}>
              <Headline level={2} className={classes.pressListEntryTitle}>
                <Link
                  to={pressPage.fields.fullPath}
                  onMouseEnter={() => setCursorType('link')}
                  onMouseLeave={() => setCursorType('')}
                  onClick={() => setCursorType('')}
                >
                  {pressPage.teaserTitle}
                </Link>
              </Headline>
              {!!pressPage.releaseDate && (
                <Paragraph className={classes.pressListEntryMeta}>
                  {formatDate(pressPage.releaseDate)}
                </Paragraph>
              )}
            </li>
          ))}
        </ul>
        {entries.length > perPage && (
          <Paginator
            count={Math.ceil(entries.length / perPage)}
            onChange={paginationHandler}
            page={page}
            className={classes.pressListPagination}
          ></Paginator>
        )}
      </Container>
    </Module>
  )
}
