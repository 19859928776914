import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  separatorRoot: {
    margin: theme.spacing(10, 0, 10, 0),
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(15, 0, 15, 0),
    },
  },
}))

export default function Separator(): ReactElement {
  const classes = useStyles()

  return <hr className={classes.separatorRoot} />
}
