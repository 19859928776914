import React, { ReactElement, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Container from '@components/modules/global/container'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  imageRoot: {
    margin: 0,
    padding: 0,
  },

  container: {
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  },

  imageWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '0',
    paddingBottom: 'calc(100% / 16 * 9)',
  },

  image: {
    width: '100%',
  },
}))

export type ImageProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  theme?: string
  anchor?: string
  image?: DBN.Contentful.IAsset
  caption?: string
}

export default function Image({
  theme,
  image,
  anchor,
  caption,
}: ImageProps): ReactElement {
  const classes = useStyles()

  const elementRef = useRef<HTMLDivElement>(null)

  return (
    <Module theme={theme} anchor={anchor}>
      <div className={classes.imageRoot} ref={elementRef}>
        <Container type="nomargin" className={classes.container}>
          {image && (
            <div className={classes.imageWrapper}>
              <GatsbyImage
                image={getImage(image) as IGatsbyImageData}
                alt={image?.description || ''}
                title={image.title}
                layout="fullWidth"
                className={clsx(classes.image)}
              />
            </div>
          )}
          {caption && <div>{caption}</div>}
        </Container>
      </div>
    </Module>
  )
}
