import React, { ReactElement, useRef, useEffect, useState } from 'react'
import clsx from 'clsx'

import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import useCustomCursor from '@system/hooks/useCustomCursor'

import Headline from '@components/text/headline'
import Paragraph from '@components/text/paragraph'
import Container from '@components/modules/global/container'
import PageLink from '@components/core/pagelink'
import Module from '@components/core/module'

const useStyles = makeStyles((theme) => ({
  teaserCardsRoot: {
    pointerEvents: 'none',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(30),
    },
  },
  teaserCardsWrapper: {
    maxWidth: '1440px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  teaserCardsContainer: {
    position: 'relative',
  },
  teaserCardsContainerHeadline: {
    paddingTop: theme.spacing(24),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(30),
    },
  },
  teaserCardsContainerLeft: {
    marginLeft: 0,
  },
  teaserCardsContainerRight: {
    marginRight: 0,
  },
  teaserCardsHeadline: {
    position: 'absolute',
    display: 'block',
    top: 0,
    right: theme.spacing(4),
    left: theme.spacing(4),
    ...theme.typography.sliderbg,
    userSelect: 'none',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
      right: theme.spacing(10),
      left: theme.spacing(10),
    },
  },
  teaserCardsHeadlineLeft: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  teaserCardsHeadlineRight: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  teaserCardsItem: {
    display: 'block',
    pointerEvents: 'auto',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  teaserCardVideoWrapper: {
    position: 'relative',
    width: '100%',
    height: '0',
    paddingBottom: (100 / 4) * 5 + '%',
  },
  teaserCardsVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center center',
  },
  teaserCardMedia: {
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
    '&:hover $teaserCardMediaZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
  },
  teaserCardMediaZoom: {
    pointerEvents: 'none',
    transition: 'transform 0.6s ease-out',
  },
  teaserCardTagline: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  teaserCardTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
}))

export type TeaserCardsProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  align?: string
  headline?: string
  pages?: Array<TeaserCardsPageProps>
}

export type TeaserCardsPageProps = {
  teaserTagline?: string
  teaserTitle?: string
  teaserCardImage?: DBN.Contentful.IAsset
  teaserCardVideo?: DBN.Contentful.IAsset
  fields: {
    fullPath: string
    isExternal: boolean
  }
}

export type TeaserCardsItemProps = {
  page: TeaserCardsPageProps
}

export function TeaserCardsMedia({ page }: TeaserCardsItemProps): ReactElement {
  const classes = useStyles()
  const { setCursorType } = useCustomCursor()
  const cursor = page.fields
    ? page.fields?.isExternal
      ? 'teaserExternal'
      : 'teaserMore'
    : ''
  const image = page.teaserCardImage ? getImage(page.teaserCardImage) : null
  const imageSRC = page.teaserCardImage ? getSrc(page.teaserCardImage) : null

  return page.teaserCardVideo ? (
    <div
      className={classes.teaserCardMedia}
      onMouseEnter={() => setCursorType(cursor)}
      onMouseLeave={() => setCursorType('')}
      onClick={() => setCursorType('')}
    >
      <div
        className={
          (classes.teaserCardVideoWrapper, classes.teaserCardMediaZoom)
        }
      >
        <video
          width="520"
          height="650"
          poster={imageSRC || ''}
          autoPlay
          muted
          loop
          playsInline
          className={classes.teaserCardsVideo}
        >
          <source
            src={page.teaserCardVideo.file?.url}
            type={page.teaserCardVideo.file?.contenttype}
          />
        </video>
      </div>
    </div>
  ) : (
    (page.teaserCardImage && image && (
      <div
        className={classes.teaserCardMedia}
        onMouseEnter={() => setCursorType(cursor)}
        onMouseLeave={() => setCursorType('')}
        onClick={() => setCursorType('')}
      >
        <GatsbyImage
          image={image}
          alt={page.teaserCardImage.description || ''}
          title={page.teaserCardImage.title}
          className={classes.teaserCardMediaZoom}
        />
      </div>
    )) || <></>
  )
}

export function TeaserCardsItem({ page }: TeaserCardsItemProps): ReactElement {
  const classes = useStyles()

  return (
    <PageLink page={page} className={classes.teaserCardsItem}>
      <TeaserCardsMedia page={page} />
      {page.teaserTagline && (
        <Paragraph className={classes.teaserCardTagline}>
          {page.teaserTagline}
        </Paragraph>
      )}
      {page.teaserTitle && (
        <Headline level={3} className={classes.teaserCardTitle}>
          {page.teaserTitle}
        </Headline>
      )}
    </PageLink>
  )
}

export default function TeaserCards({
  theme,
  anchor,
  align,
  headline,
  pages,
}: TeaserCardsProps): ReactElement {
  const classes = useStyles()

  const [offset, setOffset] = useState(0)

  const containerRef = useRef<HTMLDivElement>(null)
  const headlineRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && headlineRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const headlineRect = headlineRef.current.getBoundingClientRect()

        const start =
          containerRect.top - window.innerHeight - headlineRect.height / 2
        const stop = containerRect.top + 1.5 * headlineRect.height

        if (start < 0 && 0 < stop) {
          const position = start / (start - stop)
          setOffset(position * headlineRect.height - headlineRect.height / 2)
        }
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return pages && pages.length ? (
    <Module className={classes.teaserCardsRoot} theme={theme} anchor={anchor}>
      <div className={classes.teaserCardsWrapper} ref={containerRef}>
        <Container
          type="nomargin"
          className={clsx(classes.teaserCardsContainer, {
            [classes.teaserCardsContainerLeft]: align === 'left',
            [classes.teaserCardsContainerRight]: align === 'right',
            [classes.teaserCardsContainerHeadline]: headline,
          })}
        >
          {headline && (
            <span
              className={clsx(classes.teaserCardsHeadline, {
                [classes.teaserCardsHeadlineLeft]: align === 'left',
                [classes.teaserCardsHeadlineRight]: align === 'right',
              })}
              ref={headlineRef}
              style={{
                top: `${offset}px`,
              }}
            >
              {headline}
            </span>
          )}
          <Grid container spacing={8}>
            {pages?.map((page: TeaserCardsPageProps, i: number) => (
              <Grid item xs={12} md={6} key={i}>
                <TeaserCardsItem page={page} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </Module>
  ) : (
    <></>
  )
}
