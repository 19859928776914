import React from 'react'
import { Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, Link, useStaticQuery } from 'gatsby'

import useGlobalText from '@system/hooks/useGlobalText'
import useCustomCursor from '@system/hooks/useCustomCursor'

import Container from '@components/modules/global/container'
import Module from '@components/core/module'
import Headline from '@components/text/headline'
import Carousel from '@components/core/carousel'

const useStyles = makeStyles((theme) => ({
  headline: {
    [theme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
    paddingRight: theme.spacing(8),
  },
  teaserItemImageZoom: {
    pointerEvents: 'none',
    transition: 'transform 0.6s ease-out',
  },
  teaserItemImage: {
    display: 'block',
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
    '&:hover $teaserItemImageZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
  },
  teaserSlide: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(8),
    },
  },
}))

export type JobFixedApplyProps = DBN.IReactDefaultProps & {
  pageContext?: DBN.PageHelpers.PageContext
}

export default function JobFixedApply({
  theme,
  anchor,
}: JobFixedApplyProps): React.ReactElement {
  const classes = useStyles()
  const { getText } = useGlobalText()
  const { setCursorType } = useCustomCursor()
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  const { initiativeImage, freelanceImage } = useStaticQuery(graphql`
    query {
      initiativeImage: contentfulGlobalAsset(
        key: { eq: "job.overview.initiative.image" }
      ) {
        key
        value {
          gatsbyImageData(
            width: 520
            height: 520
            layout: FULL_WIDTH
            quality: 90
            placeholder: NONE
          )
          description
          title
        }
      }
      freelanceImage: contentfulGlobalAsset(
        key: { eq: "job.overview.freelance.image" }
      ) {
        key
        value {
          gatsbyImageData(
            width: 520
            height: 520
            layout: FULL_WIDTH
            quality: 90
            placeholder: NONE
          )
          description
          title
        }
      }
    }
  `)

  function JobApplyItem(
    headline: string,
    slug: string,
    imageData: DBN.Contentful.IAsset
  ) {
    if (!imageData.gatsbyImageData) return
    return (
      <Link to={`application/${slug}`}>
        <div
          className={classes.teaserItemImage}
          onMouseEnter={() => setCursorType('teaserMore')}
          onMouseLeave={() => setCursorType('')}
          onClick={() => setCursorType('')}
        >
          <GatsbyImage
            image={imageData.gatsbyImageData}
            alt={imageData.description || ''}
            title={imageData.title || ''}
            className={classes.teaserItemImageZoom}
          />
        </div>
        <Headline level={3}>{headline}</Headline>
      </Link>
    )
  }

  return (
    <Module theme={theme} anchor={anchor}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Headline className={classes.headline}>
              {getText('jobs.overview.freelance-initial.apply.headline')}
            </Headline>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Carousel slidesPerView={isLarge ? 2 : 1.1}>
              <div className={classes.teaserSlide} key="1">
                {JobApplyItem(
                  getText('jobs.overview.initiative.headline'),
                  'initiative-634170',
                  initiativeImage.value
                )}
              </div>
              <div className={classes.teaserSlide} key="1">
                {JobApplyItem(
                  getText('jobs.overview.freelance.headline'),
                  'freelance-634176',
                  freelanceImage.value
                )}
              </div>
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </Module>
  )
}
