import React, { ReactElement } from 'react'
import clsx from 'clsx'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { InView } from 'react-intersection-observer'
import { makeStyles } from '@material-ui/core/styles'

import useCustomCursor from '@system/hooks/useCustomCursor'

import Headline from '@components/text/headline'
import Container from '@components/modules/global/container'
import PageLink from '@components/core/pagelink'
import Module from '@components/core/module'
import Copy from '@components/core/copy'

const useStyles = makeStyles((theme) => ({
  teaserFullRoot: {
    pointerEvents: 'none',
    // paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      // paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(30),
    },
  },
  teaserFullWrapper: {
    maxWidth: '1440px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  teaserFullContainer: {},
  teaserFullContainerLeft: {
    marginLeft: 0,
  },
  teaserFullContainerRight: {
    marginRight: 0,
  },
  teaserFullItem: {
    pointerEvents: 'auto',
    display: 'block',
    transform: 'translate(0, 10vh)',
    transition: 'transform 0.6s ease-in',
    marginBottom: theme.spacing(3),
  },
  teaserFullItemImage: {
    display: 'block',
    marginBottom: theme.spacing(6),
    overflow: 'hidden',
    '&:hover $teaserFullItemImageZoom': {
      transform: 'scale(1.05)',
      transition: 'transform 0.6s ease-in',
    },
  },
  teaserFullItemImageZoom: {
    pointerEvents: 'none',
    transition: 'transform 0.6s ease-out',
  },
  teaserFullItemDescription: {
    opacity: 0,
    transition: 'opacity 0.6s',
  },
  teaserFullItemTitle: {
    marginBottom: theme.spacing(2),
  },
  teaserFullItemCopy: {
    '& > *:last-child': {
      marginBottom: 0,
    },
  },

  teaserFullItemFadeIn: {
    transform: 'translate(0, 0)',
    transition: 'transform 0.6s ease-out',

    '& $teaserFullItemDescription': {
      opacity: 1,
      transition: 'opacity 0.6s',
    },
  },
}))

export type TeaserPageProps = {
  teaserTitle?: string
  teaserCopy?: DBN.Contentful.BasicRichTextType
  teaserImage?: DBN.Contentful.IAsset
  fields: {
    fullPath: string
    isExternal: boolean
  }
}

export type TeaserFullProps = DBN.IReactDefaultProps & {
  theme?: string
  anchor?: string
  align?: string
  page?: TeaserPageProps
}

export default function TeaserFull({
  theme,
  anchor,
  align,
  page,
}: TeaserFullProps): ReactElement {
  const classes = useStyles()
  const gatsbyImage = page?.teaserImage ? getImage(page.teaserImage) : null
  const { setCursorType } = useCustomCursor()
  const cursor = page?.fields
    ? page.fields?.isExternal
      ? 'teaserExternal'
      : 'teaserMore'
    : ''

  return page ? (
    <Module theme={theme} anchor={anchor} className={classes.teaserFullRoot}>
      <div className={classes.teaserFullWrapper}>
        <Container
          type="nomargin"
          className={clsx(classes.teaserFullContainer, {
            [classes.teaserFullContainerLeft]: align === 'left',
            [classes.teaserFullContainerRight]: align === 'right',
          })}
        >
          <InView threshold={0} triggerOnce={true} delay={100}>
            {({ inView, ref }) => (
              <div ref={ref}>
                <PageLink
                  page={page}
                  className={clsx(classes.teaserFullItem, {
                    [classes.teaserFullItemFadeIn]: inView,
                  })}
                >
                  {page.teaserImage && gatsbyImage && (
                    <div
                      className={classes.teaserFullItemImage}
                      onMouseEnter={() => setCursorType(cursor)}
                      onMouseLeave={() => setCursorType('')}
                      onClick={() => setCursorType('')}
                    >
                      <GatsbyImage
                        image={gatsbyImage}
                        alt={page.teaserImage.description || ''}
                        title={page.teaserImage.title}
                        className={classes.teaserFullItemImageZoom}
                      />
                    </div>
                  )}
                  <div className={classes.teaserFullItemDescription}>
                    {page.teaserTitle && (
                      <Headline
                        className={classes.teaserFullItemTitle}
                        level={3}
                      >
                        {page.teaserTitle}
                      </Headline>
                    )}
                    {page.teaserCopy && (
                      <Copy
                        className={classes.teaserFullItemCopy}
                        richtext={page.teaserCopy}
                      />
                    )}
                  </div>
                </PageLink>
              </div>
            )}
          </InView>
        </Container>
      </div>
    </Module>
  ) : (
    <></>
  )
}
