import { useIntl } from 'react-intl'
import { parse, format, getUnixTime } from 'date-fns'

import { de, enGB as en, tr, ru, es, arSA as ar } from 'date-fns/locale'

export default function useDateFns(): {
  formatDate: typeof formatDate
  formatDateYear: typeof formatDateYear
  formatUnixTime: typeof formatUnixTime
} {
  const intl = useIntl()
  const locales: Record<string, Locale> = { de, en, tr, ru, es, ar }

  function formatDate(date: string, formatStr = 'dd. MMMM y') {
    if (typeof date === 'string') {
      //replace '.' with ' ' for Safari and Firefox compatibility
      if (date.length) {
        date = date.replace(/\./g, ' ')
      }
      return format(new Date(date), formatStr, {
        locale: locales[intl.locale],
      })
    }
  }

  function formatDateYear(date: string, formatStr: string) {
    return format(parse(date, formatStr, new Date()), 'yyyy')
  }

  function formatUnixTime(date: string, formatStr: string) {
    return getUnixTime(parse(date, formatStr, new Date()))
  }

  return {
    formatDate,
    formatDateYear,
    formatUnixTime,
  }
}
